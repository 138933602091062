/**
 * @generated SignedSource<<064e9b52c04ecd8151b29e716e8fb061>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type OrderByInput = {
  direction: OrderDirection;
  field: string;
};
export type ProgressReportTablePaginationQuery$variables = {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  groupIds?: ReadonlyArray<string> | null;
  last?: number | null;
  orderBy?: OrderByInput | null;
  search?: string | null;
  id: string;
};
export type ProgressReportTablePaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ProgressReportTable_PaginationFragment">;
  } | null;
};
export type ProgressReportTablePaginationQuery = {
  variables: ProgressReportTablePaginationQuery$variables;
  response: ProgressReportTablePaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v9 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v10 = {
  "kind": "Variable",
  "name": "before",
  "variableName": "before"
},
v11 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v12 = {
  "kind": "Variable",
  "name": "groupIds",
  "variableName": "groupIds"
},
v13 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v14 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v15 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = [
  (v9/*: any*/),
  (v10/*: any*/),
  {
    "kind": "Literal",
    "name": "excludeViewer",
    "value": true
  },
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  {
    "kind": "Literal",
    "name": "roles",
    "value": [
      "member"
    ]
  },
  (v15/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v20 = [
  (v19/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProgressReportTablePaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ProgressReportTable_PaginationFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProgressReportTablePaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "records",
                "args": (v18/*: any*/),
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "productMemberships",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "memberId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastActivityAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentCompletionNodeConnection",
                            "kind": "LinkedField",
                            "name": "curriculumModuleCompletions",
                            "plural": false,
                            "selections": (v20/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Curriculum",
                                "kind": "LinkedField",
                                "name": "curriculum",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ContentUsageNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "modules",
                                    "plural": false,
                                    "selections": [
                                      (v19/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ContentUsageNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ContentUsage",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "releasedAt",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v17/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OccurrenceAttendanceNodeConnection",
                            "kind": "LinkedField",
                            "name": "occurrenceAttendances",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OccurrenceAttendanceNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OccurrenceAttendance",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v17/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "occurrenceId",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OccurrenceNodeConnection",
                            "kind": "LinkedField",
                            "name": "eventOccurrences",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OccurrenceNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Occurrence",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v17/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "datetimeRange",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommentNodeConnection",
                            "kind": "LinkedField",
                            "name": "comments",
                            "plural": false,
                            "selections": (v20/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "member",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fullName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfoObjectType",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "records",
                "args": (v18/*: any*/),
                "filters": [
                  "search",
                  "orderBy",
                  "excludeViewer",
                  "roles",
                  "groupIds"
                ],
                "handle": "connection",
                "key": "ProgressReportTable_records",
                "kind": "LinkedHandle",
                "name": "productMemberships"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a60c61ccc2ba2bf739496ceaa0351c3f",
    "id": null,
    "metadata": {},
    "name": "ProgressReportTablePaginationQuery",
    "operationKind": "query",
    "text": "query ProgressReportTablePaginationQuery(\n  $after: String\n  $before: String\n  $first: Int\n  $groupIds: [ID!]\n  $last: Int\n  $orderBy: OrderByInput\n  $search: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ProgressReportTable_PaginationFragment_1OBRul\n    id\n  }\n}\n\nfragment EventAttendanceProgressBarFragment on ProductMembership {\n  id\n  occurrenceAttendances {\n    edges {\n      node {\n        id\n        occurrenceId\n      }\n    }\n  }\n  eventOccurrences {\n    totalCount\n    edges {\n      node {\n        id\n        datetimeRange\n      }\n    }\n  }\n}\n\nfragment MemberCurriculumProgressBarFragment on ProductMembership {\n  id\n  curriculumModuleCompletions {\n    totalCount\n  }\n  product {\n    curriculum {\n      modules {\n        totalCount\n        edges {\n          node {\n            id\n            releasedAt\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n\nfragment ProgressReportListRow_Member on ProductMembership {\n  id\n  email\n  lastActivityAt\n  ...MemberCurriculumProgressBarFragment\n  ...EventAttendanceProgressBarFragment\n  comments {\n    totalCount\n  }\n  member {\n    id\n    fullName\n    ...ProfileAvatarWithDetailsFragment\n  }\n}\n\nfragment ProgressReportTable_PaginationFragment_1OBRul on Product {\n  id\n  records: productMemberships(first: $first, after: $after, last: $last, before: $before, search: $search, orderBy: $orderBy, excludeViewer: true, roles: [member], groupIds: $groupIds) {\n    totalCount\n    edges {\n      cursor\n      node {\n        id\n        memberId\n        ...ProgressReportListRow_Member\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      startCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4fbde0530745b1aa89b3494a30d2463a";

export default node;
